import {onFind} from "@elements/init-modules-in-scope";
import {addClass, on, hasClass, removeClass, findIn, find, closest, trigger} from "@elements/dom-utils";

export function init() {
    onFind('.js-overlay__toggle', (button) => {
        let id = button.getAttribute('data-overlay-id');
        let overlay = document.getElementById(id);
        let mainContent = find('#main-content');

        if(overlay && hasClass('js-overlay', overlay)) {
            on('click', (e) => {
                if(hasClass('is-active', overlay)){
                    removeClass('is-active', overlay);
                    removeClass('is-active', button);
                    removeClass('has-overlay', mainContent);
                    removeClass('overflow-hidden', document.body);
                }else{
                    addClass('overflow-hidden', document.body);
                    addClass('has-overlay', mainContent);
                    addClass('is-active', overlay);
                    addClass('is-active', button);
                    reRender(overlay);
                    bodyClickHandler(overlay, button, mainContent);
                }
            }, button);

            let close = findIn('.js-overlay__close', overlay);
            if(close){
                on('click', (e) => {
                    if(hasClass('is-active', overlay)){
                        removeClass('overflow-hidden', document.body);
                        removeClass('is-active', overlay);
                        removeClass('is-active', button);
                        removeClass('has-overlay', mainContent);
                        trigger('overlay:close', document.body);
                    }
                }, close);
            }
        }
    });
}

function bodyClickHandler(overlay, button, mainContent) {
    window.addEventListener("click", function _listener(evt) {
        console.log('bodyclick');
        if (!overlay.contains(evt.target)) {
            evt.stopImmediatePropagation();
            removeClass('is-active', overlay);
            removeClass('is-active', button);
            removeClass('has-overlay', mainContent);
            removeClass('overflow-hidden', document.body);

            window.removeEventListener("click", _listener, true);
        }
        //remove bodyclick handler if close button is clicked
        if(evt.target === findIn('.js-overlay__close', overlay) || findIn('.js-overlay__close', overlay) === closest('.js-overlay__close', evt.target) || evt.target === findIn('.js-datepicker__input', overlay)){
            window.removeEventListener("click", _listener, true);
        }
        //remvoe bodyclick handler and overlay if product-grid is submitted
        if(closest('.js-product-grid', overlay)){
            let productGrid = closest('.js-product-grid', overlay);
            on('product-grid.success', (e) => {
                if(hasClass('is-active', overlay)){
                    removeClass('overflow-hidden', document.body);
                    removeClass('is-active', overlay);
                    removeClass('is-active', button);
                    removeClass('has-overlay', mainContent);
                    trigger('overlay:close', document.body);
                    window.removeEventListener("click", _listener, true);
                }
            }, productGrid)
        }
    }, true);
}

function reRender(element) {
    var parent = element.parentNode;
    parent.removeChild(element);
    parent.appendChild(element);
}