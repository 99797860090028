import {onFind, initInScope} from "@elements/init-modules-in-scope";
import {findIn, on} from "@elements/dom-utils";
import axios from "axios";

export function init() {
    onFind('.js-append-business-application', function (element) {
        let select = findIn('.js-append-business-application__select', element);
        let targetDiv = findIn('.js-append-business-application__target', document);

        on('change', () =>{
            let selectedOption = select.options[select.selectedIndex];
            let url = selectedOption.getAttribute('data-url');
            // console.log(url);

            if (url) {
                let pendingRequest = axios({method: "GET", url: url});

                pendingRequest.then((response) => {
                    if(response.data.success) {
                        targetDiv.innerHTML = response.data.html;
                        initInScope(targetDiv);
                    }
                }).catch((error) => {
                    console.error(error);
                });
            } else {
                console.log('No URL found');
            }
        }, select);

    });
}